export default {
  name: 'AI 쇼핑도우미',
  header: 'AI 쇼핑도우미',
  thumbnail: {
    bot: `/assets/skstoa/chat_ai.png`,
    user: `/assets/skstoa/chat_user.png`,
  },
  palette: {
    primary: '#E62642',
    primary10: '#FCE9EC',
  },
  introduction: '상품에 대해 궁금하신게 있으시다면 무엇이든 질문해보세요!',
  notice:
    '고객님의 소중한 개인정보 보호를 위해 민감한 개인정보를 입력하지 않도록 주의 바랍니다',
  madeBy: 'presented by BUZZNI',
  available: [],
};
