export default {
  name: 'AI쇼핑톡',
  header: 'AI쇼핑톡',
  thumbnail: {
    bot: `/assets/shopnt/chat_ai.png`,
    user: `/assets/shopnt/chat_user.png`,
  },
  palette: {
    primary: '#f50d87',
    primary10: '#FEE7F3',
  },
  introduction:
    '안녕하세요! 쇼핑엔티‘AI쇼핑톡‘입니다.\n상품별 할인 정보, 방송 일정 그리고 사진 업로드를 통한 유사 상품 추천까지!\n항상 최적화된 서비스를 제공하기 위하여 노력하겠습니다.',
  notice:
    '고객님의 소중한 개인정보 보호를 위해 민감한 개인정보를 입력하지 않도록 주의 바랍니다',
  madeBy: 'presented by BUZZNI',
};
