type SlackMessageType = {
  message: string;
  from: string;
  environment: string;
  [key: string]: string;
};

export function formatSlackMessage(error: SlackMessageType) {
  const { environment, ...rest } = error;
  return (
    (environment === 'production' ? ':rotating_light: ' : '') +
    `ERROR in *${environment}*
  \`\`\`${JSON.stringify(rest, null, 2)}\`\`\`
  `
  );
}
