import SSGLIVE_CONFIG from './ssglive';
import HSMOA_CONFIG from './hsmoa';
import CJMALL_CONFIG from './cjmall';
import SHOPNT_CONFIG from './shopnt';
import SKSTOA_CONFIG from './skstoa';

export default {
  ssglive: SSGLIVE_CONFIG,
  hsmoa: HSMOA_CONFIG,
  cjmall: CJMALL_CONFIG,
  shopnt: SHOPNT_CONFIG,
  skstoa: SKSTOA_CONFIG,
  default: {
    name: '옥순AI',
    header: 'OK, SOON AI',
    thumbnail: {
      bot: `/assets/oksoon.png`,
      user: `/assets/me.png`,
    },
    introduction:
      '쇼핑에 대한 모든 것을 알려드릴게요.\n무엇을 질문할지 모르겠다면, 아래 질문을 눌러보세요.',
    notice:
      '옥순AI는 OpenAI가 제공하는 서비스를 활용하는 것으로 이용 시 입력되는 데이터는 OpenAI에도 제공되며, 제공 이후 OpenAI의 데이터 관리 정책에 따라 관리됩니다. 또한 고객님의 소중한 개인정보 보호를 위해 옥순AI 이용 시 개인정보는 입력하지 않도록 주의 바랍니다.',
    madeBy: 'presented by BUZZNI',
  },
} as Record<string, any>;
