export function decoder(value: string) {
  const base64Payload = value.split('.')[1];
  if (!base64Payload) {
    console.error('[Empty data]: User token does not exist');
    return {};
  }
  const payload = Buffer.from(base64Payload, 'base64');
  const result = JSON.parse(payload.toString());
  return result;
}
