export default {
  name: '쇼핑AI',
  header: '신세계 쇼핑 AI',
  thumbnail: {
    bot: `/assets/ssglive/ssglive_chat_ai.png`,
    user: `/assets/ssglive/ssglive_chat_user.png`,
  },
  palette: {
    primary: '#ff3061',
    primary10: '#FFEAEF',
  },
  introduction:
    "안녕하세요! '신세계 쇼핑 AI'입니다.\n신세계라이브쇼핑 상품의 장단점분석부터, 할인정보, 고객리뷰, 홈쇼핑 방송일정까지\n궁금한 내용을 자유롭게 물어보세요 :)",
  notice:
    '고객님의 소중한 개인정보 보호를 위해 민감한 개인정보를 입력하지 않도록 주의 바랍니다',
  madeBy: 'presented by BUZZNI',
  available: [
    // 실서버 기준
    '8wEucMwbAMf', // 레미
    '4v6LCOFISZ1', // 미쉘
    'bK1VJFuFFG2', // 저스틴
    'G4o2IuLFzzH', // ios 15 테스트폰
  ],
};
