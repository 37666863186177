function initialize() {
  (window as any).BuzzniChatAssistant = {
    history: [],
  };
}

function historyBack(callback?: (...args: any) => void) {
  const isIframe = window.top !== window.self;
  if (isIframe) {
    const ua = navigator.userAgent.toLowerCase();
    const isSafari = ua.search('safari') >= 0 && ua.search('chrome') < 0;
    if (isSafari) {
      const history = (window as any).BuzzniChatAssistant.history;
      history.pop();
      const lastPath: string | undefined = history.pop();
      if (!!lastPath) {
        window.location.href = lastPath;
        return;
      }
    }
  }

  callback?.();
}

function pushHistory(path: string) {
  (window as any).BuzzniChatAssistant.history.push(path);
}

export default { initialize, historyBack, pushHistory };
