import axios from 'axios';
import CryptoJS from 'crypto-js';
import * as R from 'ramda';
import { closest } from '@shared/utils/dom';
import { decoder } from '@shared/utils/jwt';
import { ANALYTICS_URL } from '~/api/constants';
import { ACCESS_TOKEN_KEY } from '~/utils/constants';
import {
  ANALYTICS_PARAM_NAME,
  ANALYTICS_COMPANY,
  ANALYTICS_USER_TOKEN,
} from './constants';

const getCommonParams = () => {
  const storage = sessionStorage.getItem(ANALYTICS_PARAM_NAME);
  if (!!storage) {
    const obj = JSON.parse(storage);
    if (!!obj[ANALYTICS_USER_TOKEN] && !!obj[ANALYTICS_COMPANY]) {
      return JSON.parse(storage);
    }
  }

  const queryParams = new URLSearchParams(window.location.search);
  const site = queryParams.get('shop');

  const cookie = `; ${document.cookie}`;
  const parts = R.split(`; ${ACCESS_TOKEN_KEY}=`, cookie);
  const token = parts.length === 2 ? parts.pop()?.split(';').shift() : null;
  let userToken = '';
  if (!!token) {
    const jwt = decoder(token);
    userToken = jwt.user_token;
  }

  return {
    [ANALYTICS_COMPANY]: site || 'oksoon',
    [ANALYTICS_USER_TOKEN]: userToken,
  };
};

type DataType = {
  path: string;
  params: Record<string, any>;
};

const trigger = (data?: DataType) => {
  if (!data) return console.error('[Empty data]:', data);

  const isIframe = window.self !== window.top;
  const commonParams = isIframe ? {} : getCommonParams();
  const { path } = data;
  let { params } = data;
  params = Object.entries({ path, ...commonParams, ...params })
    .filter((v: any) => !/__(.+?)__/.test(v[0]) && !R.isNil(v[1]))
    .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {});
  (async () => {
    try {
      isIframe
        ? window.parent.postMessage(
            { buzzni_shopping_gpt_stat_params: params },
            '*'
          )
        : await axios({
            baseURL: ANALYTICS_URL,
            url: `/log/record`,
            data: params,
            method: 'post',
            headers: {
              'x-api-key':
                process.env.NEXT_PUBLIC_MODE === 'production'
                  ? process.env.NEXT_PUBLIC_ANALYTICS_API_KEY
                  : process.env.NEXT_PUBLIC_ANALYTICS_DEV_API_KEY,
            },
          });
    } catch (e) {
      console.error('[Analytics error]:', e);
    }
  })();
};

const tracker = (path: string, params: Record<string, any>) => {
  // 암호화 한후 node에 저장

  if (!!params) {
    const tracking = JSON.stringify({ path, params });
    return {
      'data-tracking': CryptoJS.AES.encrypt(
        tracking,
        process.env.NEXT_PUBLIC_AES_KEY ?? ''
      ),
    };
  }
  return {};
};

const handleClick = (e: any) => {
  const el = closest(e.target, 'a, button, [role="button"]');

  if (!!el && !!el.getAttribute('data-tracking') && e.isTrusted) {
    e.preventDefault();

    const data = CryptoJS.AES.decrypt(
      el.getAttribute('data-tracking') || '',
      process.env.NEXT_PUBLIC_AES_KEY ?? ''
    ).toString(CryptoJS.enc.Utf8);

    try {
      const tracking = JSON.parse(data);
      (async () => {
        await trigger(tracking);

        el.dispatchEvent(new e.constructor(e.type, e));
      })();
    } catch (e) {
      /* empty */
    }
  }
};

const initialize = () => {
  document.addEventListener('click', handleClick);
  return () => {
    document.removeEventListener('click', handleClick);
  };
};

export default { initialize, tracker, trigger };
