import type { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import EventEmitter from 'events';
import * as R from 'ramda';
import 'regenerator-runtime/runtime';
import useSWR, { SWRConfig } from 'swr';
import SHOP_CONFIG from '@shared/config';
import { GA_TRACKING_ID, pageview } from '@shared/utils/google-analytics';
import { decoder } from '@shared/utils/jwt';
import SharedRouter from '@shared/utils/router';
import analytics from '~/analytics';
import {
  ANALYTICS_USER_TOKEN,
  ANALYTICS_PARAM_NAME,
  ANALYTICS_COMPANY,
} from '~/analytics/constants';
import { ACCESS_TOKEN_KEY } from '~/utils/constants';
import { sendErrorReport } from '~/utils/errorReport';
import { EVENT } from '~/utils/events';
import '../styles/globals.css';

const isProduction = process.env.NEXT_PUBLIC_MODE === 'production';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [cookies] = useCookies([ACCESS_TOKEN_KEY]);
  const { mutate } = useSWR('global://e2e-test');
  const { data: windowMessage, mutate: mutateWindowMessage } = useSWR(
    'global://window-message',
    () => {
      return {} as {
        user?: Record<string, any>;
        headerTitle?: string;
      };
    },
    {
      revalidateIfStale: false,
      revalidateOnMount: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const shop = pageProps.query.shop || '';

  const handleMessage = (event: MessageEvent<any>) => {
    // origin 체크할거면 아래 주석 풀기
    // if (/^https:\/\/(.*\.buzzni\.com|.*\.oksoon\.ai)$/.test(event.origin)) {}
    const {
      buzzni_shopping_gpt_access_token: access_token,
      name = null,
      headerTitle,
    } = event.data ?? {};
    mutateWindowMessage(
      {
        user: {
          ...(windowMessage?.user ?? {}),
          accessToken: access_token,
          name,
        },
        headerTitle,
      },
      { revalidate: false }
    );
  };

  const handleErrorReport = useCallback(
    (event: CustomEvent) =>
      sendErrorReport({
        shop: new URLSearchParams(window.location.search).get('shop'),
        isIframe: window.top !== window.self,
        ...event.detail,
      }),
    []
  ) as EventListener;

  const init = () => {
    (window as any).eventEmitter = new EventEmitter();
    SharedRouter.initialize();
    analytics.initialize();
    mutate({ isReady: true });
    window.addEventListener('message', handleMessage);
    window.addEventListener(EVENT.ERROR, handleErrorReport);
    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener(EVENT.ERROR, handleErrorReport);
    };
  };
  useEffect(init, []);

  useEffect(() => {
    const handleRouteChange = (url: any) => {
      SharedRouter.pushHistory(url);
      if (isProduction) {
        pageview(url);
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const saveCommonAnalyticParams = (key: string, value: any) => {
    const storage = sessionStorage.getItem(ANALYTICS_PARAM_NAME);
    const obj: Record<string, any> = !!storage ? JSON.parse(storage) : {};
    obj[key] = value;
    sessionStorage.setItem(ANALYTICS_PARAM_NAME, JSON.stringify(obj));
  };

  const handleShopQueryChange = () => {
    saveCommonAnalyticParams(ANALYTICS_COMPANY, shop || 'oksoon');
    if (!!shop && !!SHOP_CONFIG[shop]) {
      const { palette } = SHOP_CONFIG[shop];
      R.pipe(
        R.toPairs,
        R.forEach(([key, value]: [string, string]) => {
          const bodyStyles = document.body.style;
          bodyStyles.setProperty(`--${key}`, value);
        })
      )(palette ?? {});
    }
  };
  useEffect(handleShopQueryChange, [shop]);

  useEffect(() => {
    const token = cookies[ACCESS_TOKEN_KEY];
    let userToken = '';
    if (!!token) {
      const jwtPayload = decoder(token);
      userToken = jwtPayload.user_token;
    }
    saveCommonAnalyticParams(ANALYTICS_USER_TOKEN, userToken);
  }, [cookies[ACCESS_TOKEN_KEY]]);

  return (
    <>
      <Head>
        <title>옥순AI - 쇼핑, 일일이 검색 말고 물어보세요!</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0"
        />
        <meta name="theme-color" content="#ffffff" />
        {isProduction && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
        `,
            }}
          />
        )}
      </Head>
      {!!GA_TRACKING_ID && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
      )}
      <Script src="https://cdnjs.cloudflare.com/ajax/libs/smoothscroll/1.4.10/SmoothScroll.min.js.map" />
      <SWRConfig
        value={{
          fallback: pageProps.fallback,
          revalidateOnFocus: false,
          revalidateIfStale: false,
        }}
      >
        <Component {...pageProps} />
      </SWRConfig>
    </>
  );
}

export default MyApp;

MyApp.getInitialProps = async ({ ctx, Component }: AppContext) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  pageProps = {
    ...pageProps,
    query: { ...ctx.query },
    fallback: { 'global://query': { ...ctx.query } },
  };

  return { pageProps };
};
