import api from '@shared/api';
import { formatSlackMessage } from '@shared/utils/slack';
import { ANALYTICS_URL } from '~/api/constants';

type ErrorReportType = {
  message?: string;
  shop?: string;
  productId?: string;
  isIframe?: boolean;
  [key: string]: any;
};

export function sendErrorReport({
  message,
  shop,
  productId,
  isIframe,
  ...rest
}: ErrorReportType) {
  (async () => {
    const body = {
      environment: process.env.NEXT_PUBLIC_MODE || process.env.NODE_ENV,
      message: message || '',
      from: `쇼핑어시스턴트 챗봇 ${isIframe ? 'SDK' : '웹'}`,
      company: shop || 'oksoon',
      productId: productId || '',
      pathname: window.location.pathname,
      ...rest,
    };

    // slack
    await api({
      url: `/api/error-report`,
      method: 'POST',
      data: {
        message: formatSlackMessage(body),
      },
    });

    if (process.env.NEXT_PUBLIC_MODE === 'production') {
      // kibana
      await api({
        baseURL: ANALYTICS_URL,
        url: '/log/record',
        method: 'post',
        headers: {
          'x-api-key':
            process.env.NEXT_PUBLIC_MODE === 'production'
              ? process.env.NEXT_PUBLIC_ANALYTICS_API_KEY
              : process.env.NEXT_PUBLIC_ANALYTICS_DEV_API_KEY,
        },
        data: {
          path: '/stat/error/client',
          ...body,
        },
      });
    }
  })();
}
