export default {
  name: 'CJ온스타일',
  thumbnail: {
    bot: `/assets/cjmall/cjmall_chat_ai.webp`,
    user: `/assets/me.png`,
  },
  header: 'CJ ONSTYLE',
  palette: {
    primary: '#640FAF',
    primary10: '#FAF4FF',
    // 컬러팔레트 참고: https://company.cjonstyle.com/cjonstyle/introduction.asp
  },
  introduction:
    '쇼핑에 대한 모든 것을 알려드릴게요.\n무엇을 질문할지 모르겠다면, 아래 질문을 눌러보세요.',
  notice:
    'CJ온은 OpenAI가 제공하는 서비스를 활용하는 것으로 이용 시 입력되는 데이터는 OpenAI에도 제공되며, 제공 이후 OpenAI의 데이터 관리 정책에 따라 관리됩니다. 또한 고객님의 소중한 개인정보 보호를 위해 모아 이용 시 개인정보는 입력하지 않도록 주의 바랍니다.',
  madeBy: 'presented by BUZZNI',
  sdk_title: 'CJ ONSTYLE',
};
