import axios, { AxiosRequestConfig, AxiosError } from 'axios';

export default async function api(config: AxiosRequestConfig) {
  try {
    const { data } = await axios({ ...config });
    return { response: data, error: null };
  } catch (e) {
    return { response: null, error: e as AxiosError };
  }
}
